import { PageProps } from "gatsby";
import React from "react";

interface Fee {
  channel: string;
  rate: {
    free: number;
    basic: number;
    standard: number;
    advance: number;
  };
  remark: string;
}

const fees: ReadonlyArray<Fee> = [
  {
    channel: "คิวอาร์โค้ด",
    rate: {
      free: 1.25,
      basic: 0.95,
      standard: 0.75,
      advance: 0.55,
    },
    remark: "-",
  },
  {
    channel: "โมบายแบงก์กิ้ง",
    rate: {
      free: 1.25,
      basic: 0.95,
      standard: 0.75,
      advance: 0.55,
    },
    remark: "*ขั้นต่ำ 15 บาท",
  },
  {
    channel: "บัตรเดบิต/เครดิต",
    rate: {
      free: 3.25,
      basic: 2.95,
      standard: 2.75,
      advance: 2.55,
    },
    remark: "-",
  },
  {
    channel: "ทรูมันนี่วอลเล็ต",
    rate: {
      free: 2.95,
      basic: 2.65,
      standard: 2.45,
      advance: 2.25,
    },
    remark: "-",
  },
];

interface PaymentAndWithdrawalInfo {
  channel: string;
  status: "Completed" | "Processing";
  duration: string;
}

const paymentAndWithdrawalInfos: ReadonlyArray<PaymentAndWithdrawalInfo> = [
  {
    channel: "คิวอาร์โค้ด",
    status: "Completed",
    duration: "ตามเวลาจริง",
  },
  {
    channel: "โมบายแบงก์กิ้ง",
    status: "Completed",
    duration: "ตามเวลาจริง",
  },
  {
    channel: "ทรูมันนี่วอลเล็ต",
    status: "Completed",
    duration: "ตามเวลาจริง",
  },
  {
    channel: "บัตรเดบิต / เครดิต*",
    status: "Processing",
    duration: "เมื่อสถานะ completed (รอระบบสรุปยอดชำระเงิน เวลา 21.00 น.)",
  },
];

const TermAndScopePage: React.FC<PageProps> = () => {
  return (
    <div className="container mx-auto my-8 md:my-24 px-4 md:px-32">
      <h1 className="text-4xl pb-3">ข้อตกลงการให้บริการ</h1>
      <p className="pb-3">
        หากท่านไม่ยอมรับหรือตกลงตามข้อกำหนดใดของข้อตกลงการให้บริการฉบับนี้หรือฉบับที่ได้เพิ่มเติม
        เปลี่ยนแปลงหรือแก้ไข ท่านต้องหยุดใช้บริการทันที ทั้งนี้ บริษัทฯ
        แนะนำให้ท่านพิจารณาข้อตกลงการให้บริการและนโยบายที่เกี่ยวข้องอื่น ๆ
        อย่างสม่ำเสมอเพื่อทำความเข้าใจข้อกำหนดและเงื่อนไขทั้งหมดที่บังคับใช้กับการใช้บริการของท่าน
      </p>

      {/* 1 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>1. บทนำ</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="sub-bullet-level font-graphikthloop text-sm">
            1.1
            ท่านตกลงและยืนยันว่าท่านได้อ่านและเข้าใจขอบเขตการให้บริการและข้อตกลงการให้บริการฉบับนี้
            รวมถึงฉบับที่ได้เพิ่มเติม เปลี่ยนแปลง หรือแก้ไข
            ซึ่งถือเป็นส่วนหนึ่งของข้อตกลงการให้บริการฉบับนี้และเป็นข้อตกลงที่มีผลผูกพันตามกฎหมายระหว่างท่านกับบริษัท
            ดีเปิ้ลจำกัด (“บริษัทฯ ”) และใช้กับการใช้บริการของบริษัทฯโดยท่าน
            (“บริการ”)
          </li>
          <li className="sub-bullet-level font-graphikthloop text-sm">
            1.2 หากท่านใช้บริการในนามของบริษัท ห้างหุ้นส่วน สมาคม หรือองค์กร
            (“องค์กร”)
            ท่านตกลงและยืนยันต่อไปว่าท่านมีอำนาจกระทำการผูกพันองค์กรสำหรับขอบเขตการให้บริการและข้อตกลงการให้บริการฉบับนี้
            โดยในกรณีนี้ คำว่า “ท่าน” จะรวมถึงองค์กรด้วย
          </li>
        </ul>
      </div>
      {/* 2 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>2. คำรับรองและคำรับประกัน</strong>
        </h4>
        <p className="text-sm pb-2 pl-2">
          โดยการใช้บริการนี้ ท่านรับรองและรับประกันว่า
        </p>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            2.1 ท่านมีความสามารถตามกฎหมายในการเข้าทำ
            ลงนามและปฏิบัติตามข้อผูกผันตามข้อตกลงการให้บริการฉบับนี้
          </li>
          <li className="font-graphikthloop text-sm">
            2.2 ข้อตกลงการให้บริการฉบับนี้เป็นข้อผูกพันที่ชอบด้วยกฎหมาย สมบูรณ์
            และมีผลผูกพันต่อท่านได้ตามข้อกำหนดของข้อตกลงฉบับนี้ ;
          </li>
          <li className="font-graphikthloop text-sm">
            2.3 เอกสารและ/หรือข้อมูลที่บริษัทฯ
            ได้รับหรือจะได้รับจากท่านนั้นเชื่อถือได้ เป็นจริง ครบถ้วน ถูกต้อง
            และไม่บิดเบือน และ
          </li>
          <li className="font-graphikthloop text-sm">
            2.4
            บริการและ/หรือส่วนใดส่วนหนึ่งของบริการดังกล่าวจะต้องใช้เพื่อวัตถุประสงค์อันชอบด้วยกฎหมายเท่านั้น
          </li>
        </ul>
      </div>
      {/* 3 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>3. ข้อสงวนสิทธิการรับประกัน</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            3.1 ท่านตกลงและรับทราบว่า
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.1 บริการจะถูกจัดให้บนพื้นฐานของ “สภาพจริง” และ
            “ตามเท่าที่มีอยู่” โดยไม่มีการรับประกันใด ๆ ทั้งสิ้น
            (ไม่ว่าโดยชัดแจ้งหรือโดยนัย)
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.2 บริษัทฯ และกรรมการ เจ้าหน้าที่ ลูกจ้าง และตัวแทนของบริษัทฯ
            ขอสงวนสิทธิการรับประกันใด ๆ (ไม่ว่าโดยชัดแจ้งหรือโดยนัย)
            ซึ่งรวมถึงแต่ไม่จำกัดเพียง
            การรับประกันโดยนัยซึ่งความสามารถในทางการค้า
            ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ
            ประสิทธิภาพหรือความเหมาะสมตามความมุ่งหมายในการใช้งานของท่าน
            กรรมสิทธิ์ และการไม่ถูกละเมิดการบริการรวมถึงข้อมูล เนื้อหา
            และสิ่งใดทั้งหมดที่อยู่ในบริการดังกล่าว
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.3
            ข้อมูลใดหรือสิ่งใดที่ดาวน์โหลดหรือได้มาด้วยประการใดผ่านการใช้บริการถูกเข้าถึงตามดุลยพินิจและความเสี่ยงของท่าน
            และท่านจะรับผิดชอบต่อความเสียหายที่เกิดขึ้นกับอุปกรณ์ของท่านหรือการสูญหายของข้อมูลที่อาจเกิดจากการดาวน์โหลดข้อมูลหรือสิ่งดังกล่าวแต่เพียงผู้เดียว
            และ
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.4 บริษัทฯ
            เชื่อมต่อบริการบางส่วนกับระบบและ/หรือข้อมูลของบุคคลภายนอกและเนื้อหาหรือข้อมูลที่ได้รับและได้มาจากบุคคลภายนอก
            ดังนั้น บริษัทฯ ไม่ประกัน รับรอง หรือรับประกันในความถูกต้อง
            และความครบถ้วน หรือประสิทธิภาพ คุณภาพ
            และประสิทธิผลของบริการของบุคคลภายนอกนั้นแต่อย่างใด
          </li>
          <li className="font-graphikthloop text-sm">
            3.2 บริษัทฯ ไม่รับรองหรือรับประกันว่า (ก)
            บริการหรือส่วนใดส่วนหนึ่งของบริการ เชื่อถือได้ พร้อมใช้งาน
            เป็นปัจจุบัน หรือปราศจากข้อผิดพลาด
            หรือใช้ได้อย่างต่อเนื่องหรือปลอดภัย (ข) ความผิดพลาดใด ๆ
            ในบริการจะได้รับการแก้ไข และ (ค)
            แต่ละแพลตฟอร์มหรือเซิร์ฟเวอร์ของแพลตฟอร์มนั้นปราศจากไวรัสหรือส่วนประกอบที่เป็นอันตราย
            ส่งผ่านได้อย่างปลอดภัย หรือทำงานได้อย่างเหมาะสม
            <br />
          </li>
        </ul>
      </div>
      {/* 4 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>4. สิทธิในทรัพย์สินทางปัญญา</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            4.1
            สิทธิในทรัพย์สินทางปัญญาทั้งหมดอันเกิดจากหรือเกี่ยวเนื่องกับบริการ
            (นอกเหนือจากสิทธิในทรัพย์สินทางปัญญาในสิ่งใดที่ท่านจัดหา)
            เป็นของบริษัทฯ
          </li>
          <li className="font-graphikthloop text-sm">
            4.2 ท่านจะไม่ (ก) อนุญาตช่วง โอนแก่บุคคลภายนอก
            หรือให้บุคคลภายนอกแสวงหาประโยชน์ในทางพาณิชย์ด้วยประการใด
            หรือทำให้บุคคลภายนอกเข้าถึงแพลตฟอร์มและ/หรือบริการไม่ว่าในทางใด (ข)
            ทำซ้ำ ทำสำเนา ดัดแปลง
            หรือลอกเลียนแพลตฟอร์มหรือบริการและ/หรือซอฟต์แวร์บนเซิร์ฟเวอร์
            (Server) หรือไวร์เลส (Wireless)
            หรืออุปกรณ์ที่ใช้อินเทอร์เน็ตเป็นพื้นฐาน หรือ (ค) แยกส่วน แปลง
            ถอดแบบ ถอดรหัส หรือพยายามที่จะแยก Source Code
            ของหรือแบ่งแยกซอฟต์แวร์จากแพลตฟอร์มหรือระบบ
          </li>
        </ul>
      </div>
      {/* 5 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>5. การคุ้มครองข้อมูล</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            ท่านตกลงและรับทราบว่าบริษัทฯ
            จะประมวลผลข้อมูลส่วนบุคคลของท่านตามประกาศความเป็นส่วนตัว (Privacy
            Notice)ที่ระบุบนเว็บไซต์ของบริษัทฯ (https://www.deeple.ai/privacy)
            (“ประกาศความเป็นส่วนตัว”)
            และยืนยันว่าท่านได้อ่านและเข้าใจข้อกำหนดที่ระบุในประกาศความเป็นส่วนตัวครบถ้วนแล้วและรับทราบและตกลงต่อไปว่าบริษัทฯ
            อาจแก้ไขประกาศความเป็นส่วนตัวได้
          </li>
        </ul>
      </div>
      {/* 6 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>6. การชดใช้ค่าเสียหาย</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            ท่านต้องชดใช้ค่าเสียหายและป้องกันความเสียหายแก่บริษัทฯ กรรมการ
            เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ และตัวแทนของบริษัทฯ ภายใน 30
            วันนับแต่วันที่ได้รับข้อเรียกร้องจากหรือต่อความสูญเสีย ความรับผิด
            การเรียกร้อง การกระทำ การทวงถาม ความเสียหาย ต้นทุน และค่าใช้จ่าย
            (รวมทั้งค่าฟ้องร้องดำเนินคดีและค่าทนายความ) ที่เกิดขึ้นโดยบริษัทฯ
            หรือบุคคลใดดังกล่าวของบริษัทฯ อันเป็นผลจากหรือเกี่ยวเนื่องกับ (ก)
            การใช้บริการหรือการใช้บริการที่ผิดโดยท่าน (ข)
            การผิดข้อกำหนดข้อใดของข้อตกลงการให้บริการฉบับนี้ (ค)
            การละเมิดสิทธิของบุคคลภายนอกโดยท่าน หรือ (ง)
            การให้บริการโดยใช้หรืออ้างถึงข้อมูลที่ผิด ไม่ถูกต้อง
            หรือบิดเบือนที่ท่านจัดหาเว้นแต่เกิดจากหรือเป็นผลจากความจงใจหรือประมาทเลินเล่ออย่างร้ายแรงของบริษัทฯ
          </li>
        </ul>
      </div>
      {/* 7 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>7. การจำกัดความรับผิด</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            7.1 บริษัทฯ กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ
            หรือตัวแทนของบริษัทฯ ไม่ต้องรับผิดสำหรับความเสียหายโดยตรง
            ความเสียหายโดยอ้อม หรือค่าเสียหายสืบเนื่อง
            อันเกิดจากหรือเกี่ยวเนื่องกับ
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            7.1.1 การขัดข้อง การระงับชั่วคราว
            หรือการยกเลิกของบริการหรือส่วนใดส่วนหนึ่งของบริการดังกล่าวตามข้อตกลงการให้บริการฉบับนี้
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            7.1.2
            การใช้บริการผิดหรือการใช้บริการที่ไม่เหมาะสมโดยท่านและ/หรือลูกจ้าง
            เจ้าหน้าที่ ผู้ที่ท่านแต่งตั้ง หรือผู้แทนของท่าน
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            7.1.3 การสื่อสารหรือข้อตกลงใดในช่องทางการสนทนา (Chat Channel)
            ระหว่างลูกค้าและ/หรือสมาชิกและแชทบอทปัญญาประดิษฐ์ (AI Chatbot) และ
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            7.1.4 การกระทำหรืองดเว้นกระทำการของท่าน ลูกจ้าง เจ้าหน้าที่
            ผู้ที่ท่านแต่งตั้ง หรือผู้แทนของท่าน และบุคคลภายนอก
          </li>
          <li className="font-graphikthloop text-sm">
            7.2 ข้อเรียกร้องใด โดยท่านต่อบริษัทฯ กรรมการ เจ้าหน้าที่ ลูกจ้าง
            ผู้รับมอบอำนาจ ที่ปรึกษา ผู้แทน หรือตัวแทนของบริษัทฯ
            สำหรับความสูญหายหรือความเสียหายที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการใช้บริการหรือความไม่สามารถใช้บริการจำกัดไว้เพียงเท่าจำนวนเงินรวมของจำนวนค่าบริการที่ชำระโดยท่านสำหรับการใช้บริการก่อนยื่นข้อเรียกร้องดังกล่าว
            <br />
          </li>
        </ul>
      </div>
      {/* 8 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>8. การแก้ไขเพิ่มเติม</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            8.1 ท่านตกลงและรับทราบว่าบริษัทฯ อาจเพิ่มเติม
            เปลี่ยนแปลงหรือแก้ไขข้อกำหนดใดที่ระบุในข้อตกลงการให้บริการนี้ได้ทุกเมื่อตามที่บริษัทฯ
            เห็นสมควรและท่านตกลงที่จะผูกพันตามข้อตกลงที่เพิ่มเติม
            เปลี่ยนแปลงหรือแก้ไขนั้นทุกประการ
          </li>
          <li className="font-graphikthloop text-sm">
            8.2 การเพิ่มเติม เปลี่ยนแปลง
            หรือแก้ไขข้อตกลงการให้บริการฉบับนี้จะประกาศและ/หรือแจ้งให้ท่านทราบเป็นลายลักษณ์อักษรภายใน
            7 วันก่อนมีผลบังคับใช้
          </li>
          <li className="font-graphikthloop text-sm">
            8.3 หากมีการเพิ่มเติม การเปลี่ยนแปลง หรือการแก้ไขเป็นผล (ก)
            ก่อให้เกิดภาระหน้าที่เพิ่มขึ้น และ/หรือ (ข)
            ก่อให้เกิดภาระค่าใช้จ่ายหรือความรับผิดเพิ่มขึ้น และบริษัทฯ
            ไม่ได้รับคำคัดค้านจากท่านเป็นลายลักษณ์อักษรภายใน 15
            วันนับจากวันที่ระบุบนเว็บไซต์และ/หรือการแจ้งเป็นลายลักษณ์อักษรของบริษัทฯ
            ให้ถือว่าท่านตกลงและยอมรับการเพิ่มเติม
            การเปลี่ยนแปลงหรือการแก้ไขดังกล่าวแล้ว
          </li>
          <li className="font-graphikthloop text-sm">
            8.4
            การใช้บริการอย่างต่อเนื่องของท่านภายต่อไปหลังจากการประกาศหรือการแจ้งแก้ไข
            เปลี่ยนแปลง
            หรือเพิ่มเติมไม่ว่าท่านจะพิจารณาแล้วหรือไม่ถือว่าท่านยอมรับและตกลงที่จะผูกพันตามการเพิ่มเติม
            การเปลี่ยนแปลง หรือการแก้ไขนั้นแล้ว
          </li>
        </ul>
      </div>
      {/* 9 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>9. การหยุด การระงับชั่วคราว และการยกเลิก</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            9.1 บริษัทฯ
            ขอสงวนสิทธิที่จะหยุดให้บริการได้ไม่ว่าทั้งหมดหรือแต่บางส่วนตามกำหนดระยะเวลาตามปกติหรือประการอื่นใดตามหนังสือบอกกล่าวล่วงหน้าหรือโดยปราศจากหนังสือบอกกล่าวล่วงหน้า
            รวมถึง (ก) การบำรุงรักษาและการซ่อมแซมเซิร์ฟเวอร์ (Server)
            เครือข่ายสัญญาณ (Network) ซอฟต์แวร์
            และ/หรือแพลตฟอร์มซึ่งจำเป็นสำหรับการจัดเตรียมการเข้าถึงบริการแก่ท่าน
            และ (ข) เหตุสุดวิสัย (ตามที่นิยามด้านล่างนี้)
          </li>
          <li className="font-graphikthloop text-sm">
            9.2 บริษัทฯ
            ขอสงวนสิทธิในการระงับชั่วคราวหรือยกเลิกบัญชีของท่านและ/หรือการเข้าถึงบริการไม่ว่าทั้งหมดหรือบางส่วน
            เนื่องจากเหตุการณ์ดังต่อไปนี้
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.2.1 ท่านไม่ปฏิบัติตามหรือผิดข้อกำหนดในข้อตกลงการให้บริการนี้
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.2.2 ท่านไม่ชำระเงินซึ่งครบกำหนดชำระภายใต้ข้อตกลงการให้บริการนี้
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.2.3 ข้อมูลใด ๆ รวมถึงรายละเอียดบริการ สินค้า ผลิตภัณฑ์
            หรือราคาที่ท่านให้แก่บริษัทฯ ผิดพลาด ไม่ถูกต้อง หรือบิดเบือน
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.2.4 เหตุสุดวิสัย หรือ
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.2.5 การให้บริการของบริษัทฯ
            ผิดกฎหมายหรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชนหรือบริษัทฯ
            มีเหตุอันควรเชื่อได้ว่ามีการใช้บริการหรือการเข้าทำธุรกรรมใดด้วยระบบตอบรับอัตโนมัติหรือมีการใช้บริการในทางที่ผิดหรือในทางที่มิชอบ
          </li>
          <li className="font-graphikthloop text-sm">
            9.3
            โดยไม่กระทบต่อสิทธิและหน้าที่ของคู่สัญญาแต่ละฝ่ายที่มีอยู่จนถึงเวลาที่ยกเลิกบริการและสิทธิและการเยียวยาอื่นใดที่มีอยู่ภายใต้ข้อตกลงการให้บริการ
            บริษัทฯ
            อาจยกเลิกการให้บริการโดยมีผลทันทีโดยส่งหนังสือบอกกล่าวแก่ท่านเมื่อเกิดเหตุการณ์อย่างใดอย่างหนึ่งดังต่อไปนี้
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.3.1
            ท่านกระทำการผิดข้อกำหนดที่เป็นสาระสำคัญหรือไม่ปฏิบัติหน้าที่อย่างใดอย่างหนึ่งที่กำหนดในข้อตกลงการให้บริการและ
            (หากเยียวยาแก้ไขได้) ไม่เยียวยาแก้ไขความผิดนั้นภายในระยะเวลา 14 วัน
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.3.2 ท่านเลิกกิจการ หรือมีคำสั่งให้เลิกกิจการ หรือล้มละลาย
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.3.3
            ท่านตกเป็นผู้ล้มละลายหรือถือจะตกเป็นผู้ล้มละลายรือไม่สามารถชำระหนี้เมื่อถึงกำหนดชำระได้ภายใต้กฎหมายที่ใช้บังคับ
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.3.4 เหตุสุดวิสัย หรือ
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            9.3.5
            มีการเปลี่ยนแปลงกฎหมายหรือกฎเกณฑ์ของหน่วยงานราชการซึ่งทำให้การปฏิบัติหน้าที่ตามข้อตกลงการให้บริการนี้ผิดกฎหมาย
          </li>
          <li className="font-graphikthloop text-sm">
            9.4
            ท่านอาจบอกเลิกหรือยกเลิกการใช้บริการส่วนใดได้ทุกเมื่อและตกลงและรับทราบว่าบริษัทฯ
            มีสิทธิคิดค่าธรรมเนียมการให้บริการ ค่าธรรมเนียมเพิ่มเติม ค่าขนส่ง
            ค่าไปรษณีย์ ค่าธรรมเนียมและค่าใช้จ่ายอื่น ๆ
            หรือเงินหรือค่าตอบแทนที่ค้างชำระซึ่งเกิดขึ้นจากการใช้บริการของท่านได้
            (“ค่าธรรมเนียม”)
            โดยหักจากบัตรเครดิตหรือบัตรเดบิตของท่านจนกว่าการบอกเลิกหรือการยกเลิกนั้นจะมีผลใช้บังคับ
          </li>
          <li className="font-graphikthloop text-sm">
            9.5 เพื่อหลีกเลี่ยงข้อสงสัย
            ท่านตกลงและรับทราบว่าค่าธรรมเนียมซึ่งท่านชำระให้แก่บริษัทฯ
            เป็นค่าบริการไม่สามารถขอคืน แลกเปลี่ยน โอน
            หรือแลกคืนได้ไม่ว่าในกรณีใด
            ภายใต้ข้อจำกัดใดที่กำหนดโดยบทบัญญัติแห่งกฎหมาย
            นโยบายการไม่คืนค่าบริการนี้ให้มีผลบังคับใช้ได้ตลอดระยะเวลาไม่ว่าท่านหรือบริษัทฯ
            จะตัดสินใจบอกเลิกหรือยกเลิกการใช้บริการส่วนใดก็ตาม
          </li>
        </ul>
      </div>
      {/* 10 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>10. เหตุสุดวิสัย</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            10.1 บริษัทฯ มิได้ผิดข้อตกลงการให้บริการหรือรับผิดสำหรับ
            ความล่าช้าในการดำเนินการ
            หรือไม่สามารถปฏิบัติหน้าที่ใดหากความล่าช้าหรือความไม่สามารถดำเนินการดังกล่าวเป็นผลจากเหตุการณ์
            พฤติการณ์ หรือเหตุอันใดที่นอกเหนือการควบคุมตามสมควรของบริษัทฯ
            รวมถึงความชำรุดเสียหายของคอมพิวเตอร์ เซิร์ฟเวอร์ (Server)
            หรืออุปกรณ์ หรือความล้มเหลวของบริการอินเทอร์เน็ต เว็บไซต์ แพลตฟอร์ม
            หรือแพลตฟอร์มของบุคคลภายนอกไม่ว่าด้วยเหตุใด
            รวมถึงกรณีการถูกโจมตีจากแฮ็คเกอร์ กรณีการถูกไวรัสคอมพิวเตอร์
            กรณีมีผลกระทบอย่างมีนัยสำคัญที่เกิดขึ้นโดยการเปลี่ยนแปลงของเทคโนโลยีด้านโทรคมนาคม
            หรือกรณีมีคำสั่งของหน่วยงานราชการหรือกฎหมาย (“เหตุสุดวิสัย”)
          </li>
          <li className="font-graphikthloop text-sm">
            10.2 เมื่อเกิดเหตุสุดวิสัยขึ้น บริษัทฯ
            อาจระงับบริการหรือให้บริการล่าช้า
            ไม่ว่าบางส่วนหรือทั้งหมดแก่ท่านในระหว่างที่เหตุการณ์หรือพฤติการณ์ดังกล่าวยังคงอยู่
            และตกลงจะใช้ความพยายามที่เหมาะสมในทางพาณิชย์เพื่อดำเนินการลดผลกระทบจากการระงับหรือความล่าช้าดังกล่าวซึ่งในกรณีใดก็ตาม
            ท่านตกลงและรับทราบว่าบริษัทฯ ไม่ต้องรับผิดหรือมีหน้าที่ใด ๆ
            ต่อท่านสำหรับการระงับหรือความล่าช้าดังกล่าว
          </li>
        </ul>
      </div>
      {/* 11 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>11. การรักษาความลับ</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            ภายใต้เงื่อนไขของกฎหมายที่ใช้บังคับหรือหน่วยงานราชการหรือหน่วยงานกำกับดูแล
            คู่สัญญาแต่ละฝ่ายตกลงเก็บรักษาข้อมูลที่มีลักษณะเป็นความลับซึ่งเปิดเผยโดยคู่สัญญาอีกฝ่ายหนึ่งไว้เป็นความลับอย่างเคร่งครัด
            ซึ่งรวมถึงข้อมูลใด ๆ ที่เกี่ยวข้องกับหรือในเรื่องของกิจการ บริการ
            ผลิตภัณฑ์ ลูกค้า ผู้จัดหา (Suppliers)
            หรือแผนการส่งเสริมการขายและการตลาดของคู่สัญญาอีกฝ่ายหนึ่งนั้น
            และจะไม่เปิดเผยข้อมูลนั้นให้แก่บุคคลใดโดยปราศจากความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากคู่สัญญาอีกฝ่าย
            (เว้นแต่ เป็นการเปิดเผยแก่กรรมการ ลูกจ้าง
            หรือที่ปรึกษาเท่าที่จำเป็นต้องรู้ข้อมูลนั้น
            โดยที่บุคคลดังกล่าวผูกพันในการปฏิบัติหน้าที่ในการรักษาความลับตามที่ระบุไว้ในนี้ด้วย)
            หรือใช้ข้อมูลนั้นเพื่อวัตถุประสงค์อื่นใดนอกจากการปฏิบัติหน้าที่ตามบันทึกข้อตกลงฉบับนี้
          </li>
        </ul>
      </div>
      {/* 12 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>12. คำบอกกล่าวและข้อมูลการติดต่อ</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            12.1
            คำบอกกล่าวหรือการสื่อสารใดที่ให้หรือส่งให้ที่เกี่ยวเนื่องกับข้อตกลงการให้บริการฉบับนี้ให้ทำเป็นลายลักษณ์อักษรถึงคู่สัญญาที่เกี่ยวข้อง
            ณ ที่อยู่และ/หรืออีเมล์ที่ระบุไว้ในข้อตกลงการให้บริการนี้
            (กรณีส่งโดยท่าน) หรือที่ระบุในเอกสารบันทึกของบริษัทฯ (กรณีบริษัทฯ
            เป็นผู้ส่ง) คำบอกกล่าวดังกล่าวจะถือว่าส่งโดยชอบแล้วหาก (ก)
            ส่งโดยพนักงานส่งเอกสาร เมื่อนำส่งแล้ว (ข)
            ส่งทางไปรษณีย์หรือไปรษณีย์แบบพิเศษ เมื่อนำส่งแล้ว และ (ค)
            ส่งทางอีเมล์ เมื่อส่งออกจากระบบแล้ว
          </li>
        </ul>
      </div>
      {/* 13 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>13. ภาษี ต้นทุน และค่าใช้จ่าย</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            ท่านจะรับผิดชอบภาษีใด ๆ ที่เกี่ยวข้อง (นอกเหนือจากภาษีหัก ณ
            ที่จ่ายในประเทศไทย ซึ่งหนังสือรับรองการหักภาษี ณ
            ที่จ่ายถูกนำส่งให้แก่บริษัทฯ ) ค่าอากร ต้นทุน และ/หรือ
            ค่าใช้จ่ายที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการเข้าทำหรือการปฏิบัติตามข้อตกลงการให้บริการฉบับนี้และต้องชดใช้แก่บริษัทฯ
            สำหรับจำนวนเงินที่เกี่ยวข้อง ซึ่งบริษัทฯ ได้จ่ายไป
          </li>
        </ul>
      </div>
      {/* 14 */}
      <div>
        <h4 className="pb-2">
          <strong>14. การแบ่งแยกได้ของข้อกำหนด</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            ข้อกำหนดใดไม่ชอบด้วยกฎหมาย ตกเป็นโมฆะ หรือมิอาจใช้บังคับได้
            ให้ข้อกำหนดข้อนั้นไม่มีผลบังคับเพียงเท่าที่ไม่สามารถบังคับใช้ได้โดยไม่กระทบความสมบูรณ์ของข้อกำหนดอื่นใดของข้อตกลงการให้บริการนี้
            นอกจากนี้ ความไม่ชอบด้วยกฎหมาย
            การตกเป็นโมฆะหรือการใช้บังคับมิได้ดังกล่าวไม่มีทำให้เป็นโมฆะซึ่งข้อกำหนดดังกล่าวหรือข้อกำหนดอื่น
            ๆ ในเขตอำนาจทางกฎหมายอื่น
          </li>
        </ul>
      </div>
      {/* 15 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>15. การโอนสิทธิ</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            15.1 บริษัทฯ
            อาจโอนสิทธิหรือโอนโดยวิธีการใดซึ่งสิทธิหรือหน้าที่ตามข้อตกลงการให้บริการฉบับนี้ได้ทุกเมื่อ
          </li>
          <li className="font-graphikthloop text-sm">
            15.2
            ท่านจะไม่โอนสิทธิหรือโอนโดยวิธีการใดซึ่งสิทธิหรือหน้าที่ตามข้อตกลงการให้บริการฉบับนี้ได้โดยปราศจากความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัทฯ
          </li>
        </ul>
      </div>
      {/* 16 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>16. กฎหมายที่ใช้บังคับ</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            ขอบเขตการให้บริการ ข้อตกลงการให้บริการฉบับนี้
            และประเด็นที่เกี่ยวกับความสมบูรณ์ การตีความ
            และการบังคับใช้ใช้บังคับตามกฎหมายแห่งประเทศไทย
          </li>
        </ul>
      </div>

      {/* Scope of Services */}
      <h2 className="text-4xl pb-3">ขอบเขตการให้บริการ</h2>
      <p className="pb-3">
        ขอบเขตการให้บริการฉบับนี้จะต้องอ่านประกอบกับและถือเป็นส่วนหนึ่งของข้อตกลงการให้บริการ
        ท่านตกลงและรับทราบว่าข้อกำหนดใดที่ระบุในขอบเขตการให้บริการนี้อาจถูกเพิ่มเติมเปลี่ยนแปลง
        หรือแก้ไขได้ทุกเมื่อหากมีการเปลี่ยนแปลง
        หรือการเพิ่มเติมบริการและ/หรือส่วนใดของบริการและท่านตกลงผูกพันตามข้อกำหนดที่เพิ่มเติม
        เปลี่ยนแปลงหรือแก้ไขนั้นทุกประการ
      </p>
      {/* 1 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>1. การบริการของบริษัทฯ</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            1.1 บริษัทฯ ให้บริการระบบแชทบอทปัญญาประดิษฐ์ขั้นสูง (“AI Chatbot”)
            และระบบการบริหารการจัดเก็บข้อมูลซึ่งรวมถึงคำสั่งอย่างมืออาชีพ
            การบริหารสินค้าคงคลัง การตอบคำถามตลอด 24 ชั่วโมง
            และตัวแทนในการตอบแชท (Live Chat) เครื่องมือทางการตลาด
            และรายงานการขายพร้อมด้วยแผนผังและสถิติแสดงยอดขายรวม คำสั่งซื้อ
            ลูกค้า และมูลค่าคำสั่งซื้อเฉลี่ยในแต่ละช่วงเวลาผ่านระบบซอฟต์แวร์
            และ/หรือ
            แพลตฟอร์มเทคโนโลยีเพื่อช่วยพัฒนาประสิทธิภาพการขายในกิจการของท่าน
            (ต่อไปนี้จะเรียกรวมกันว่า “บริการพื้นฐาน”)
          </li>
          <li className="font-graphikthloop text-sm">
            1.2 หากท่านประสงค์ ท่านอาจใช้บริการทางเลือก ดังต่อไปนี้ได้
            (“บริการทางเลือก”)
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            1.2.1
            บริการเพิ่มเติมในฐานะรายการเสริมจากบริการพื้นฐานเพื่อพัฒนาความสัมพันธ์ของท่านกับลูกค้าของท่าน
            (“บริการเสริม”)
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            1.2.2
            บริการจัดส่งโดยบริษัทขนส่งซึ่งเป็นบุคคลภายนอกเพื่อช่วยจัดส่งสินค้าของท่าน
            (“บริการจัดส่ง”)
          </li>
          <li className="font-graphikthloop text-sm">
            1.3 บริษัทฯ จะให้บริการพื้นฐานและบริการทางเลือก (เรียกรวมกันว่า
            “บริการ”) ผ่านเว็บไซต์ (https://www.deeple.ai/) แอพพลิเคชั่น
            และ/หรือ แพลตฟอร์มของคู่ค้าของบริษัทฯ
            หรือคู่ค้าอื่นตามสัญญาที่บริษัทฯอาจเข้าผูกพันในอนาคต
            (แต่ละแห่งเรียกว่า “แพลตฟอร์ม”)
          </li>
          <li className="font-graphikthloop text-sm">
            1.4 ท่านต้องสร้างแชทบอทโดยจัดเตรียมข้อมูลร้านค้า รายละเอียด ประเภท
            และรายการของสินค้า วิธีการชำระเงินและวิธีการจัดส่ง ระยะเวลาการจัดส่ง
            และข้อมูลของการสนทนาในบัญชีร้านค้าที่เปิดกับแพลตฟอร์มของบุคคลภายนอก
            ซึ่งแพลตฟอร์มจะสร้างระบบจัดเก็บให้กับร้านค้าของท่านและใช้ AI Chatbot
            กับช่องทางการสนทนาของบัญชร้านค้าของท่าน (“ช่องทางการสนทนา”
            และเรียกรวมกันว่า “โครงการ”)
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            1.5 เมื่อท่านเข้าผูกพันกับลูกค้าและ/หรือสมาชิก บริษัทฯ โดย AI
            Chatbot
            จะเก็บและบันทึกโดยทันทีซึ่งรายละเอียดการติดต่อของลูกค้าและ/หรือสมาชิกของท่าน
            ประวัติและความสนใจในการซื้อของบุคคลดังกล่าว
            และการสื่อสารใดในช่องทางการสนทนาระหว่างลูกค้าของท่านและ/หรือสมาชิกและ
            AI Chatbot
            ในฐานข้อมูลเพื่อปรับแต่งบริการให้เข้ากับบุคคลดังกล่าวและพัฒนาบริการต่อไป
          </li>
          <li className="font-graphikthloop text-sm">
            1.6 ท่านตกลงและยืนยันว่าการสื่อสารโดย AI Chatbot
            ในช่องทางการสนทนาจะถูกสร้างโดยข้อมูลที่จัดให้โดยท่านและ/หรือสมาชิกของโครงการและท่านจะรับผิดชอบต่อความเสียหายโดยตรง
            ความเสียหายโดยอ้อม
            ความเสียหายพิเศษหรือความเสียหายสืบเนื่องที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการสื่อสารดังกล่าว
          </li>
          <li className="font-graphikthloop text-sm">
            1.7 บริษัทฯ
            อาจบันทึกและ/หรือประมวลผลข้อมูลที่เกี่ยวข้องกับโครงการและ/หรือการกระทำใด
            ที่เกี่ยวข้องกับการใช้บริการเพื่อวัตถุประสงค์ในการพัฒนาและให้บริการ
            ในกรณีนี้
            ท่านตกลงที่จะรักษาและแก้ไขปรับปรุงข้อมูลที่เกี่ยวข้องกับโครงการให้เป็นปัจจุบันในช่วงเวลาที่เหมาะสมอยู่เสมอเพื่อให้ข้อมูลดังกล่าวนั้นถูกต้อง
            เป็นปัจจุบัน และครบถ้วนในระหว่างระยะเวลาการให้บริการ
          </li>
          <li className="font-graphikthloop text-sm">
            1.8 บริษัทฯ
            สงวนสิทธิในการเปลี่ยนแปลงและ/หรือยกเลิกด้านใดของบริการหรือส่วนใดของบริการตามที่จำเป็นเพื่อดำเนินการหรือปรับปรุงบริการ
          </li>
          <li className="font-graphikthloop text-sm">
            1.9 ท่านตกลงและรับทราบว่าบริษัทฯ
            อาจแก้ไขปรับปรุงบริการให้เป็นปัจจุบันเพื่อพัฒนาประสิทธิภาพการทำงานหรือปรับปรุงปัญหาด้านความปลอดภัยซึ่งท่านอาจต้องติดตั้งการปรับปรุงแก้ไขนั้นกับบริการ
            การไม่ติดตั้งการปรับปรุงแก้ไขดังกล่าวหรือการเลือกไม่ให้มีการปรับปรุงแก้ไขโดยอัตโนมัติ
            ท่านอาจไม่สามารถใช้บริการต่อเนื่องไปได้ ในกรณีนี้ บริษัทฯ
            ไม่รับประกันว่าการแก้ไขปรับปรุงดังกล่าวจะยังใช้กับอุปกรณ์หรือระบบของท่านได้
            การแก้ไขปรับปรุงบริการทั้งหมดอยู่ภายใต้ขอบเขตการให้บริการและข้อตกลงการให้บริการเว้นแต่บริษัทฯ
            จะกำหนดไว้เป็นประการอื่นใด
          </li>
        </ul>
      </div>
      {/* 2 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>
            2. บัญชีของท่าน
            <br />
          </strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            2.1 ท่าน (รวมถึงสมาชิก (ตามที่นิยามไว้ด้านล่างนี้)
            ต้องสร้างบัญชีกับบริษัทฯ (“บัญชี”) โดยจะต้องให้ชื่อ อีเมล์
            ชื่อผู้ใช้และรหัสผ่านแก่บริษัทฯ
          </li>
          <li className="font-graphikthloop text-sm">
            2.2 ท่านจะต้องทำหน้าที่เป็นผู้บริหารจัดการ (“แอดมิน”) และตกลงว่า (ก)
            ท่านเป็นเจ้าของและมีอำนาจอย่างสมบูรณ์ในการดำเนินการและจัดการโครงการและสามารถควบคุมการเข้าถึงโครงการโดยเจ้าหน้าที่หรือลูกจ้างทั้งหมดของท่าน
            (“สมาชิก”) และ (ข)
            การใช้บัญชีถือเป็นการลงลายมือชื่ออิเล็กทรอนิกส์ภายใต้กฎหมายที่เกี่ยวข้อง
          </li>
          <li className="font-graphikthloop text-sm">
            2.3 การใดที่ได้กระทำลง หรือดำเนินการ
            หรือเกิดขึ้นโดยแอดมินและ/หรือสมาชิกมีผลผูกพันและสมบูรณ์เสมือนหนึ่งว่าท่านได้กระทำการนั้นด้วยตนเองโดยไม่จำต้องลงลายมือชื่อในเอกสารอื่นใด
          </li>
          <li className="font-graphikthloop text-sm">
            2.4 ท่าน (รวมถึงแอดมินและสมาชิกอื่น) ต้อง (ก)
            รักษาบัญชีให้ปลอดภัยและเป็นความลับ (ข)
            ไม่อนุญาตให้ผู้อื่นใช้หรือโอนบัญชีให้แก่บุคคลหรือนิติบุคคลอื่น และ
            (ค)
            แจ้งให้บริษัทฯทราบทันทีถึงการใช้หรือเข้าถึงบัญชีและ/หรือโครงการที่ไม่ได้รับอนุญาต
            <br />
          </li>
        </ul>
      </div>
      {/* 3 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>3. แผนการบริการ (Package) และค่าธรรมเนียม</strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">3.1 แผนการหลัก</li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.1 ท่านอาจเลือกแผนการบริการหลักซึ่งปรับแต่งให้สอดคล้องกับ (ก)
            จำนวนสมาชิกของท่าน และ (ข) คำสั่งรายเดือนของท่าน (“แผนการหลัก”)
            ตามอัตราที่กำหนดในเว็บไซต์ของบริษัทฯ (https://www.deeple.ai/pricing)
            ก่อนใช้บริการ (“ค่าธรรมเนียม”)
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.2 บริการของบริษัทฯ
            ที่จัดให้แก่ท่านอาจถูกระงับชั่วคราวในกรณีที่จำนวนสมาชิกเกินจำนวนยอดสมาชิกที่อนุญาตตามแผนการหลัก
            ในกรณีนี้
            ท่านอาจสมัครใช้แผนการหลักเพิ่มเติมเพื่อใช้บริการต่อไปได้ในอัตราที่กำหนดบนเว็บไซต์ของบริษัทฯ
            (https://www.deeple.ai/pricing)
            และแผนการหลักเพิ่มเติมนี้จะเป็นแผนการหลักสำหรับรอบการจ่ายค่าบริการรายเดือนในรอบถัดไป
            เพื่อวัตถุประสงค์ของข้อกำหนดข้อนี้
            สมาชิกหมายถึงลูกค้าที่มีปฏิสัมพันธ์กับ AI Chatbot
            ในช่องทางการสนทนาไม่ว่าธุรกรรมการขายนั้นจะสำเร็จหรือไม่ก็ตาม
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.1.3
            หากจำนวนคำสั่งรายเดือนที่สำเร็จเกินจำนวนยอดคำสั่งรายเดือนที่อนุญาตภายใต้แผนการหลัก
            ให้คิดค่าธรรมเนียมเกินส่วนสำหรับคำสั่งซื้อที่เกินมานั้นตามอัตราที่กำหนดบนเว็บไซต์ของบริษัทฯ
            (https://www.deeple.ai/pricing) (“ค่าธรรมเนียมเกินส่วน”)
          </li>
          <li className="font-graphikthloop text-sm">3.2 บริการเสริม</li>
          <li className="font-graphikthloop text-sm pl-4">
            3.2.1 ท่านตกลงชำระค่าธรรมเนียมให้บริษัทฯ ตามอัตราที่กำหนดบนเว็บไซต์
            (https://www.deeple.ai/pricing) ก่อนใช้บริการเสริม
            (“ค่าธรรมเนียมการใช้บริการเสริม”)
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.2.2
            บริการเสริมบางประเภทกำหนดจำนวนยอดการใช้งานรายเดือนไว้และหากจำนวนการใช้งานของท่านเกินจำนวนยอดบริการเสริมที่ท่านเลือก
            ค่าธรรมเนียมสำหรับส่วนที่เกินกำหนดนั้นจะคิดตามอัตราที่กำหนดไว้บนเว็บไซต์ของบริษัทฯ
            (https://www.deeple.ai/pricing)
            (“ค่าธรรมเนียมเกินส่วนสำหรับบริการเสริม”)
            <br />
          </li>
          <li className="font-graphikthloop text-sm">3.3 บริการจัดส่ง</li>
          <li className="font-graphikthloop text-sm pl-4">
            3.3.1 ท่านต้องเติมเงินในกระเป๋าเงินอิเล็กทรอนิกส์สำหรับการจัดส่ง
            (“กระเป๋าเงินสำหรับการจัดส่ง”) ก่อนใช้บริการจัดส่ง
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.3.2 เมื่อจัดส่งเสร็จเรียบร้อยแล้ว บริษัทฯ
            จะหักเงินต่อไปนี้จากกระเป๋าเงินสำหรับการจัดส่ง (ก)
            ค่าธรรมเนียมการจัดส่ง (รวมถึงภาษีและภาษีมูลค่าเพิ่ม)
            ซึ่งจะกำหนดตามอัตราที่กำหนดโดยบริษัทขนส่งซึ่งเป็นบุคคลภายนอกที่ท่านเลือก
            (ข) ค่าใช้จ่ายอื่น ๆ
            ที่เกิดขึ้นตามสมควรที่เกี่ยวเนื่องกับบริการจัดส่ง (เรียกรวมกันว่า
            “ค่าธรรมเนียมการจัดส่ง”) และ (ค) ค่าความสำเร็จจำนวน 2 บาท
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.3.3
            ท่านตกลงและรับทราบว่าค่าธรรมเนียมการจัดส่งที่แสดงไว้ในเบื้องต้นในขณะทำการว่าจ้างนั้นเป็นค่าธรรมเนียมโดยประมาณซึ่งพิจารณาจากสถานที่ที่ให้โดยท่านหรือลูกค้าของท่าน
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            3.3.4 หากท่านเลือกการส่งของแบบชำระเงินสด
            ท่านตกลงและอนุญาตให้บริษัทส่งของซึ่งเป็นบุคคลภายนอกเก็บค่าธรรมเนียมการจัดส่งจากลูกค้าของท่านได้โดยตรง
          </li>
        </ul>
      </div>
      {/* 4 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>
            4. เงื่อนไขการชำระเงิน
            <br />
          </strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">
            4.1
            ค่าบริการและค่าธรรมเนียมการใช้บริการเสริมต้องชำระล่วงหน้าในแต่ละเดือน
            หากท่านสมัครบริการเสริมก่อนรอบการจ่ายค่าบริการรายเดือนในรอบถัดไป
            ให้คิดค่าบริการในอัตราตามสัดส่วน (Pro-Rata Rate) ตัวอย่างเช่น
            หากสมัครแผนการหลักในวันที่ 5 ท่านจะถูกคิดเงินในวันที่ 5 ของทุกเดือน
            เว้นแต่จะตรงกับวันที่ 29 วันที่ 30 และวันที่ 31
            ซึ่งในกรณีนี้ท่านจะถูกคิดเงินในวันแรกของเดือนถัดไป
          </li>
          <li className="font-graphikthloop text-sm">
            4.2 หากท่านซื้อแผนบริการหลักเพิ่มเติมตามข้อ 3.1.2
            ก่อนรอบการจ่ายค่าบริการรายเดือนในรอบถัดไป
            ค่าบริการที่เกี่ยวข้องจะถูกคิดทันทีเมื่อสมัครใช้โดยคิดตามสัดส่วนสำหรับการจ่ายค่าบริการรายเดือนส่วนที่เหลือ
          </li>
          <li className="font-graphikthloop text-sm">
            4.3 ค่าธรรมเนียมเกินส่วน ค่าธรรมเนียมเกินส่วนสำหรับบริการเสริม
            และ/หรือค่าธรรมเนียมใดที่เกิดขึ้นตามสมควรที่เกี่ยวเนื่องกับการใช้บริการของท่านจะต้องชำระจนเต็มจำนวน
            ณ สิ้นรอบการจ่ายค่าบริการรายเดือนในแต่ละรอบ
            (“ค่าธรรมเนียมชำระในภายหลัง”)
          </li>
          <li className="font-graphikthloop text-sm">
            4.4 ค่าบริการ ค่าธรรมเนียมการใช้บริการเสริม
            และค่าธรรมเนียมชำระในภายหลัง (“ค่าธรรมเนียม”)
            จะต้องชำระผ่านบัตรเครดิตหรือบัตรเดบิตตามข้อมูลที่ให้บริษัทฯ
            และท่านต้องอนุญาตให้บริษัทฯ
            หักค่าธรรมเนียมดังกล่าวจากบัตรเครดิตหรือบัตรเดบิตนั้นได้
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            4.5 หากชำระเงินข้ามประเทศหรือชำระด้วยเงินตราต่างประเทศ
            ท่านจะต้องรับผิดชอบส่วนต่างอัตราแลกเปลี่ยนหรือค่าธรรมเนียมเพิ่มเติมที่เกี่ยวข้องแต่เพียงผู้เดียวและอนุญาตให้บริษัทฯ
            หักเงินจำนวนดังกล่าวจากบัตรเครดิตหรือบัตรเดบิตในทันที
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            4.6 หากท่านไม่ดำเนินการชำระเงินค่าธรรมเนียมส่วนใดหรือปรากฎว่าบริษัทฯ
            ไม่สามารถหักเงินจากบัตรเครดิตหรือบัตรเดบิตของท่านได้ บริษัทฯ
            จะแจ้งให้ท่านชำระเงินในทันที หากไม่ดำเนินการดังกล่าว บริษัทฯ
            สงวนสิทธิในการระงับการให้บริการจนกว่าท่านจะชำระเงิน
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            4.7 ค่าธรรมเนียมที่ต้องชำระแก่บริษัทฯ ไม่สามารถขอคืน แลกเปลี่ยน โอน
            หรือและคืนได้ไม่ว่าในกรณีใด
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            4.7 ค่าธรรมเนียมที่ต้องชำระแก่บริษัทฯ ไม่สามารถขอคืน แลกเปลี่ยน โอน
            หรือและคืนได้ไม่ว่าในกรณีใด
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            4.8 ค่าบริการ ค่าธรรมเนียมการใช้บริการเสริม ค่าจัดส่ง
            ค่าธรรมเนียมชำระในภายหลัง
            และจำนวนเงินทั้งปวงที่ถึงกำหนดต้องชำระภายใต้การใช้บริการจะต้องชำระแก่บริษัทฯ
            เต็มจำนวนโดยไม่หักกลบลบหนี้ คัดค้าน
            หรือหักออกใดเว้นแต่กรณีการหักภาษี ณ ที่จ่ายตามกฎหมายแห่งประเทศไทย
            ซึ่งหนังสือรับรองการหัก ณ ที่จ่ายจะต้องจัดส่งแก่บริษัทฯ
            พร้อมค่าธรรมเนียมดังกล่าวข้างต้น
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            4.9 ท่านอาจติดตามสถานะแผนบริการหลักและบริการเสริมของท่านได้
            รวมทั้งตรวจสอบสรุปรายการค่าใช้จ่ายตามหน้ารายการค่าใช้จ่ายบนเว็บไซต์ของบริษัทฯ
            (https://www.deeple.ai/pricing)
            และค่าธรรมเนียมใดที่อาจเกิดขึ้นเนื่องจากตรวจดูที่โครงการของท่าน
            <br />
          </li>
        </ul>
      </div>

      {/* 5 */}
      <div className="py-3">
        <h4 className="pb-2">
          <strong>
            5. การชำระค่าธรรมเนียม
            <br />
          </strong>
        </h4>
        <ul role="list" className="list-none pl-2">
          <li className="font-graphikthloop text-sm">5.1 อัตราค่าธรรมเนียม</li>
          <li className="font-graphikthloop text-sm pl-4">
            5.1.1 ค่าธรรมเนียมสำหรับการรับการชำระเงิน (Transaction Fee)
            ในกรณีรับชำระเงิน บริษัทฯ จะเก็บค่าธรรมเนียมการรับชำระเงิน
            ในอัตราร้อยละ [•] ของยอดขายของแต่ละรายการที่สั่งซื้อ
            (ไม่รวมภาษีมูลค่าเพิ่ม 7%) ตามอัตราที่ระบุในเอกสารแนบท้าย 1
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.1.2 ค่าธรรมเนียมสำหรับการถอนเงิน (Withdrawal Fee) บริษัทฯ
            จะเก็บค่าธรรมเนียมการถอนเงินครั้งละ 15 บาท (รวมภาษีมูลค่าเพิ่ม 7%)
            สำหรับทุกแผนการบริการ
          </li>
          <li className="font-graphikthloop text-sm">
            5.2 ช่องทางการชำระเงิน
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            ท่านจะต้องชำระค่าธรรมเนียมให้บริษัทฯ ผ่านทางแอพพลิเคชัน Deeple Pay
            (“Deeple Pay”) โดยดำเนินการผ่านทางช่องทางการชำระเงินดังต่อไปนี้
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.2.1 คิวอาร์โค้ด (QR Code)
            ซึ่งรองรับการใช้งานผ่านการแสกนบนแอพพลิเคชั่นทุกธนาคาร
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.2.2 โมบายแบงก์กิ้ง (Mobile Banking) ได้แก่ แอพพลิเคชั่น SCB Easy
            ของธนาคารไทยพาณิชย์ KPLUS ของธนาคารกสิกรไทย หรือ KMA
            ของธนาคารกรุงศรีอยุธยา โดยจะต้องชำระขั้นต่ำ 20 บาท
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.2.3 บัตรเครดิตหรือบัตรเดบิตของ Visa Mastercard และ JCB
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.2.4 กระเป๋าเงินอิเล็กทรอนิกส์ (E-Wallet) ได้แก่ ทรูมันนี่วอลเล็ต
            (True Money)
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            ทั้งนี้ ระบบจะแสดงสถานะการชำระเงินและการถอนเงินจาก Deeple Pay
            ตามที่ระบุในเอกสารแนบท้าย 2
            <br />
          </li>
          <li className="font-graphikthloop text-sm">
            5.3 การผูกบัญชีธนาคาร และการยืนยันบัญชีธนาคาร
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            เพื่อยืนยันว่าท่านไม่มีส่วนเกี่ยวข้องกับธุรกรรมการฟอกเงิน
            หรือคดีทุจริตใด ๆ
            และเพื่อป้องกันการโจรกรรมหรือแอบอ้างตัวตนในการทำธุรกรรมผ่าน AI
            Chatbot ท่านจะต้องดำเนินการ ดังต่อไปนี
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.3.1 การระบุตัวตน
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-8">
            ท่านจะต้องกรอกข้อมูลบุคคลธรรมดาหรือข้อมูลนิติบุคคล
            พร้อมทั้งแนบเอกสารประกอบ ดังนี้
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-8">
            - กรณีบุคคลธรรมดา ได้แก่
            บัตรประจำตัวประชาชนที่ไม่หมดอายุซึ่งแสดงรูปใบหน้าและข้อมูลในบัตรอย่างชัดเจน
            และ
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-8">
            - กรณีนิติบุคคล ได้แก่ ใบทะเบียนพาณิชย์
            หนังสือรับรองนิติบุคคลหรือใบทะเบียนภาษีมูลค่าเพิ่ม
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-4">
            5.3.2 การยืนยันบัญชีธนาคาร
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-8">
            ท่านจะต้องกรอกรายละเอียดบัญชีธนาคาร พร้อมแนบเอกสารประกอบ ดังนี้
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-8">
            - สมุดบัญชีธนาคาร
            หรือเอกสารที่ออกโดยธนาคารซึ่งปรากฎชื่อเจ้าของบัญชีตรงกับชื่อที่ใช้ในการระบุตัวตน
            <br />
          </li>
          <li className="font-graphikthloop text-sm pl-8">
            - ในกรณีบัญชีกระแสรายวันหรือบัญชีเดินสะพัด
            เอกสารที่ออกโดยธนาคารซึ่งปรากฎชื่อเจ้าของบัญชีตรงกับชื่อที่ใช้ในการระบุตัวตน
            และระบุชื่อเจ้าของบัญชี และเลขที่บัญชีอย่างชัดเจน เช่น
            หนังสือรับรองการมีบัญชีเงินฝาก หรือหนังสือรับรองการเปิดบัญชี
            ใบเสร็จรับเงินหรือใบแจ้งเงินเข้าบัญชีกระแสรายวัน
            รายการเดินบัญชีเงินฝากกระแสรายวัน (Statement)
          </li>
        </ul>
      </div>

      {/* 6 */}
      <div className="py-3">
        {/* Appendix 1 */}
        <div className="w-full text-center text-sm">เอกสารแนบท้าย 1</div>
        <div className="w-full text-center text-sm">
          อัตราค่าธรรมเนียมการชำระเงิน*
        </div>

        <table className="table-auto w-full my-2">
          <thead>
            <tr>
              <th className="border border-black text-sm">
                ช่องทางการชำระเงิน
              </th>
              <th className="border border-black px-2 text-sm text-center">
                Free Plan
              </th>
              <th className="border border-black px-2 text-sm text-center">
                Basic Plan
              </th>
              <th className="border border-black px-2 text-sm text-center">
                Standard Plan
              </th>
              <th className="border border-black px-2 text-sm text-center">
                Advanced Plan
              </th>
              <th className="border border-black px-2 text-sm text-center">
                หมายเหตุ
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee) => {
              return (
                <tr key={fee.channel}>
                  <td className="border border-black px-2 text-xs text-center">
                    {fee.channel}
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {fee.rate.free}%
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {fee.rate.basic}%
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {fee.rate.standard}%
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {fee.rate.advance}%
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {fee.remark}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="font-graphikthloop text-xs">
          (*) หมายเหตุ อัตราค่าธรรมเนียมอาจมีการเปลี่ยนแปลง ซึ่งบริษัทฯ
          จะแจ้งให้ท่านทราบต่อไป
        </div>

        <br />
        <br />

        {/* Appendix 2 */}
        <div className="w-full text-center text-sm">เอกสารแนบท้าย 2</div>
        <div className="w-full text-center text-sm">
          สถานะการชำระเงินและการถอนเงินจาก Deeple Pay
        </div>

        <table className="table-auto w-full my-2">
          <thead>
            <tr>
              <th className="border border-black text-sm">
                ช่องทางการชำระเงิน
              </th>
              <th className="border border-black px-2 text-sm text-center">
                สถานะการชำระเงิน
              </th>
              <th className="border border-black px-2 text-sm text-center">
                การถอนเงินจาก deeple pay
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentAndWithdrawalInfos.map((info) => {
              return (
                <tr key={info.channel}>
                  <td className="border border-black px-2 text-xs text-center">
                    {info.channel}
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {info.status}
                  </td>
                  <td className="border border-black px-2 text-xs text-center font-graphikthloop">
                    {info.duration}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="font-graphikthloop text-xs">
          (*) หมายเหตุ ในกรณีการชำระเงินด้วยบัตรเดบิต / เครดิต
          ระบบจะสรุปยอดชำระเงินดังกล่าวในเวลา 21.00 น. ของทุกวัน
          โดยรายการสั่งซื้อที่ชำระเงิน (ก) ภายในเวลา 20.00 น.
          ระบบจะแสดงสถานะการชำระเงินเป็นกำลังประมวลผล (Processing)
          ซึ่งถือว่าการชำระเงินดังกล่าวเสร็จสิ้นแล้ว
          และระบบจะเปลี่ยนสถานะการชำระเงินเป็นสำเร็จ (Completed) ในเวลา 21.00 น.
          ของวันที่ทำรายการสั่งซื้อดังกล่าว (ข) ตั้งแต่เวลา 20.00 น. เป็นต้นไป
          ระบบจะแสดงสถานะการชำระเงินเป็นกำลังประมวลผล (Processing)
          และระบบจะเปลี่ยนสถานะการชำระเงินเป็น (Completed) ในเวลา 21.00 น.
          ของวันถัดไป
        </div>
      </div>
    </div>
  );
};

export default TermAndScopePage;
